import React from "react";
import classNames from "classnames";
import Image, { StaticImageData } from "next/image";

interface Props {
    left?: React.ReactNode;
    right?: React.ReactNode;
    orientation?: "ltr" | "rtl" | "50";
    background?: StaticImageData;
    overlay?: boolean;
    overlayClass?: string;
    children?: React.ReactNode;
}

const ContainerBuilder: React.FC<Props> = ({
    left,
    right,
    orientation = "ltr",
    background,
    overlay = true,
    overlayClass = "bg-primary bg-opacity-70",
    children,
}) => {
    return (
        <div className="relative">
            {background && (
                <Image
                    layout="fill"
                    className="pointer-events-none object-cover object-center"
                    src={background.src}
                    alt={"test"}
                />
            )}
            <div
                className={classNames("z-1 relative", {
                    [overlayClass]: background && overlay,
                })}
            >
                <div className="container mx-auto flex flex-wrap items-center justify-between ">
                    {!children && (
                        <>
                            <div
                                className={classNames(
                                    {
                                        "sm:w-2/3": orientation === "ltr",
                                        "sm:w-1/3": orientation === "rtl",
                                        "sm:w-1/2": orientation === "50",
                                    },
                                    "flex w-full p-4",
                                )}
                            >
                                {left}
                            </div>
                            <div
                                className={classNames(
                                    {
                                        "sm:w-1/3": orientation === "ltr",
                                        "sm:w-2/3": orientation === "rtl",
                                        "sm:w-1/2": orientation === "50",
                                    },
                                    "flex w-full p-4",
                                )}
                            >
                                {right}
                            </div>
                        </>
                    )}

                    {children && <div className={"w-full p-4"}>{children}</div>}
                </div>
            </div>
        </div>
    );
};

export default ContainerBuilder;
