import Image from "next/image";
import short_purple_logo from "../public/img/logo/viva_short_purple.png";
import long_purple_logo from "../public/img/logo/viva_long_purple.png";
import short_white_logo from "../public/img/logo/viva_short_white.png";
import long_white_logo from "../public/img/logo/viva_long_white.png";
import short_gray_logo from "../public/img/logo/viva_short_gray.png";
import long_gray_logo from "../public/img/logo/viva_long_gray.png";

interface Props {
    type:
        | "short_purple"
        | "short_white"
        | "long_white"
        | "long_purple"
        | "short_gray"
        | "long_gray";
}

const Logo: React.FC<Props> = ({ type }) => {
    const renderLogo = () => {
        switch (type) {
            case "short_purple":
                return (
                    <Image
                        src={short_purple_logo}
                        alt={"Viva Short Purple Logo"}
                        layout={"fill"}
                    />
                );

            case "long_purple":
                return (
                    <Image
                        src={long_purple_logo}
                        alt={"Viva Long Purple Logo"}
                        layout={"fill"}
                    />
                );

            case "short_white":
                return (
                    <Image
                        src={short_white_logo}
                        alt={"Viva Short White Logo"}
                        layout={"fill"}
                    />
                );

            case "long_white":
                return (
                    <Image
                        src={long_white_logo}
                        alt={"Viva Long White Logo"}
                        layout={"fill"}
                    />
                );

            case "short_gray":
                return (
                    <Image
                        src={short_gray_logo}
                        alt={"Viva Short Gray Logo"}
                        layout={"fill"}
                    />
                );

            case "long_gray":
                return (
                    <Image
                        src={long_gray_logo}
                        alt={"Viva Long Gray Logo"}
                        layout={"fill"}
                    />
                );
        }
    };

    return <div className={"relative h-[2rem] w-[7.3rem]"}>{renderLogo()}</div>;
};

export default Logo;
