import Image, { StaticImageData } from "next/image";
import classNames from "classnames";
import React from "react";

interface Props {
    hero: StaticImageData;
    children: React.ReactNode;
    overlay?: boolean;
}

const Hero: React.FC<Props> = ({ hero, children, overlay = true }) => {
    return (
        <div className="relative">
            {hero && (
                <Image
                    layout="fill"
                    className="pointer-events-none object-cover object-center"
                    src={hero}
                    alt={"Background"}
                />
            )}
            <div
                className={classNames("z-1 relative", {
                    "bg-primary bg-opacity-70": overlay,
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default Hero;