import React from "react";
import CustomUrl from "./CustomUrl";
import Logo from "./Logo";
import { Facebook, Instagram, Twitter, Youtube } from "react-feather";

const Footer: React.FC = () => {
    return (
        <div
            className={
                "flex w-full flex-wrap items-center justify-between px-12 py-4"
            }
        >
            <div className={"flex w-48 flex-col"}>
                <Logo type={"short_gray"} />
                <span className={"text-xs text-vivaGray"}>
                    www.vivatrucking.com
                </span>
            </div>

            <div className={"flex flex-col flex-wrap justify-center space-y-2"}>
                <span className={"inline-flex flex-wrap space-x-4"}>
                    <CustomUrl
                        href={"/"}
                        text={"Home"}
                        textColorClass={"text-vivaGray"}
                        hoverColorClass={"hover:text-gray-900"}
                    />
                    <CustomUrl
                        href={"/about"}
                        text={"About"}
                        textColorClass={"text-vivaGray"}
                        hoverColorClass={"hover:text-gray-900"}
                    />
                    <CustomUrl
                        href={"/apply"}
                        text={"Apply"}
                        textColorClass={"text-vivaGray"}
                        hoverColorClass={"hover:text-gray-900"}
                    />
                    <CustomUrl
                        href={"/contact"}
                        text={"Contact"}
                        textColorClass={"text-vivaGray"}
                        hoverColorClass={"hover:text-gray-900"}
                    />
                    <CustomUrl
                        href={"https://vivatrucks.com/"}
                        text={"DriversHub"}
                        type={"external"}
                        textColorClass={"text-vivaGray"}
                        hoverColorClass={"hover:text-gray-900"}
                    />
                    <CustomUrl
                        href={"https://status.vivatrucks.com/"}
                        text={"Service Status"}
                        textColorClass={"text-vivaGray"}
                        hoverColorClass={"hover:text-gray-900"}
                    />
                </span>

                <span className={"mx-auto inline-block text-sm text-vivaGray"}>
                    Owned and operated by{" "}
                    <span className={"font-semibold"}>Viva Group US</span> &copy;{new Date().getFullYear()}
                </span>
            </div>

            <div className={"flex w-52 flex-col justify-end space-y-2"}>
                <div className={"flex justify-end space-x-4"}>
                    <a href={"https://twitter.com/vivatrucking"} target={"_blank"} rel={"noreferrer"}>
                        <Twitter size={20} />
                    </a>
                    <a href={"https://www.instagram.com/vivatrucking/"} target={"_blank"} rel={"noreferrer"}>
                        <Instagram size={20} />
                    </a>
                    <a href={"https://www.facebook.com/vivatruckingco/"} target={"_blank"} rel={"noreferrer"}>
                        <Facebook size={20} />
                    </a>
                    <a href={"https://www.youtube.com/channel/UCN-TK1s4t18zf--Q29Ss6nw/"} target={"_blank"} rel={"noreferrer"}>
                        <Youtube size={20} />
                    </a>
                </div>

                <span className={"text-right text-xs text-vivaGray "}>
                    <a href={"mailto:info@vivatrucking.com"}>
                        Email: info@vivatrucking.com
                    </a>
                </span>

                <span
                    className={
                        "inline-flex justify-end space-x-2 text-xs font-semibold text-vivaGray"
                    }
                >
                    <a href={"/terms-privacy"} target={"_blank"} rel={"noreferrer"}>
                        Terms of Service
                    </a>
                    <span>|</span>
                    <a href={"/terms-privacy"} target={"_blank"} rel={"noreferrer"}>
                        Privacy Policy
                    </a>
                </span>
            </div>
        </div>
    );
};

export default Footer;
