import Link, { LinkProps } from "next/link";
import React from "react";

type Props = LinkProps & {
    children: React.ReactNode;
};

const MyLink = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
    let { href, children, ...rest } = props;
    return (
        <Link href={href}>
            <a ref={ref} {...rest}>
                {children}
            </a>
        </Link>
    );
});

MyLink.displayName = "MyLink";

export default MyLink;
