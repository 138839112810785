import Logo from "./Logo";
import CustomUrl from "./CustomUrl";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import CustomLink from "./CustomLink";

const Navbar = () => {
    return (
        <nav className="flex h-16 w-full flex-wrap items-center justify-between px-12 py-10">
            <div className="inline-block flex items-center">
                <CustomLink href={"/"}>
                    <Logo type={"short_white"} />
                </CustomLink>
            </div>

            <div className="relative flex flex-wrap items-center space-x-6">
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full cursor-pointer justify-center font-semibold text-white duration-150 ease-in-out hover:text-gray-200">
                            Discover
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right rounded-lg bg-white p-2">
                            <Menu.Item>
                                <CustomLink href={"/about"}>
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        About Us
                                    </span>
                                </CustomLink>
                            </Menu.Item>
                            <Menu.Item>
                                <CustomLink href={"/divisions"}>
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        Divisions
                                    </span>
                                </CustomLink>
                            </Menu.Item>
                            <Menu.Item>
                                <CustomLink href={"https://truckersmp.com/vtc/10-viva-trucking/events"}>
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        Events
                                    </span>
                                </CustomLink>
                            </Menu.Item>
                            <Menu.Item>
                                <CustomUrl
                                    href={"https://map.vivatrucks.com/"}
                                    type="external"
                                >
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        Driver Map
                                    </span>
                                </CustomUrl>
                            </Menu.Item>
                            <Menu.Item>
                                <CustomUrl
                                    href={"https://www.vivatrucking.shop/"}
                                    type="external"
                                >
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        Merch Store
                                    </span>
                                </CustomUrl>
                            </Menu.Item>
                            <Menu.Item>
                                <CustomLink href={"/code-of-conduct"}>
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        Code of Conduct
                                    </span>
                                </CustomLink>
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>

                <CustomUrl href={"/apply"} text={"Apply"} />

                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full cursor-pointer justify-center font-semibold text-white duration-150 ease-in-out hover:text-gray-200">
                            Contact
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right rounded-lg bg-white p-2">
                            <Menu.Item>
                                <CustomLink href={"/contact"}>
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        Contact Us
                                    </span>
                                </CustomLink>
                            </Menu.Item>
                            <Menu.Item>
                                <CustomUrl
                                    href={
                                        "https://discord.com/servers/viva-trucking-373990092068356097"
                                    }
                                    type="external"
                                >
                                    <span className="flex w-full p-2 text-sm text-neutral-700 duration-150 ease-in-out hover:text-primary">
                                        Discord
                                    </span>
                                </CustomUrl>
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>

                <CustomUrl
                    href={"https://vivatrucks.com/"}
                    text={"DriversHub"}
                    type={"external"}
                />
            </div>
        </nav>
    );
};

export default Navbar;
