import Link, { LinkProps } from "next/link";
import classNames from "classnames";
import CustomLink from "./CustomLink";

type Props = LinkProps & {
    text?: string;
    children?: React.ReactNode;
    type?: "page" | "external";
    textColorClass?: string;
    hoverColorClass?: string;
};

const CustomUrl: React.FC<Props> = ({
    type = "page",
    text,
    textColorClass = "text-white",
    hoverColorClass = "hover:text-gray-200",
    children,
    ...rest
}) => {
    const showText = () => {
        if (text) {
            return (
                <span
                    className={classNames(
                        "cursor-pointer font-semibold duration-150 ease-in-out",
                        textColorClass,
                        hoverColorClass,
                    )}
                >
                    {text}
                </span>
            );
        }

        return children;
    };

    if (type === "external") {
        return (
            <a target={"_blank"} href={rest.href as string} rel={"noreferrer"}>
                {showText()}
            </a>
        );
    }

    return <CustomLink {...rest}>{showText()}</CustomLink>;
};

export default CustomUrl;
